import BannerButton from '@/app/components/BannerButton';
import Footer from '@/app/components/Footer';
import { useTheme } from '@/app/components/Theme';
import { cn } from '@/utils/helper';
import Image from 'next/image';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const heroImages = [
  {
    text: 'Dream big, work hard: Your success is within reach!',
    image: '/home/hero-1.jpg',
    color: 'blue',
  },
  {
    text: 'Fuel your body, ignite your passion: Nutrition is key!',
    image: '/home/hero-2.jpg',
    color: 'green',
  },
  {
    text: 'Put it on the calendar, and make it happen!',
    image: '/home/hero-3.jpg',
    color: 'yellow',
  },
  {
    text: 'Every step counts: Progress is made one day at a time!',
    image: '/home/hero-4.jpg',
    color: 'red',
  },
  {
    text: 'Believe in yourself: The limit is only in your mind!',
    image: '/home/hero-5.jpg',
    color: 'purple',
  },
  {
    text: 'Stay committed, stay strong, anything is possible!',
    image: '/home/hero-1.jpg',
    color: 'cyan',
  },
  {
    text: 'Push your limits: Growth happens outside your comfort zone!',
    image: '/home/hero-2.jpg',
    color: 'brown',
  },
  {
    text: 'Consistency is key: Small efforts lead to big results!',
    image: '/home/hero-3.jpg',
    color: 'orange',
  },
  {
    text: 'Embrace the uncomfortable: Every challenge is an chance!',
    image: '/home/hero-4.jpg',
    color: 'red',
  },
  {
    text: 'Your journey is unique: Celebrate every milestone you achieve!',
    image: '/home/hero-5.jpg',
    color: 'purple',
  },
];

const features = [
  {
    title: 'Align Your Workouts with Your Daily Activities.',
    description:
      'Focus on the present by effectively matching your workouts with your daily activities. This approach not only helps you stay committed to your fitness goals but also allows you to evaluate your progress in real-time. By structuring your workouts for the week ahead, you can ensure that each session is purposeful and aligned with your current lifestyle, making every effort count.',
    imageLight: '/home/feature-2.png',
    imageDark: '/home/feature-2-dark.png',
  },
  {
    title: 'Empower Your Fitness Journey with AI Coach.',
    description:
      'Select an AI coach to assist in planning your workouts. The AI coach will analyze your activities, planned workouts, heart rate zones, preferred sports types, and goals to create a personalized workout plan. You can also ask your coach any questions about your training, including nutrition, recovery, and other related topics. If the workout is not challenging enough or too hard, you can request adjustments or manually create a workout yourself.',
    imageLight: '/home/feature-1.gif',
    imageDark: '/home/feature-1-dark.gif',
  },
  {
    title: 'Workout Details.',
    description:
      'With an intuitive UI that fits your mobile and desktop screens, you can easily view and modify the details of your workout plan, including intensity, duration, and type of workout. You can add notes to the workout or create new workouts manually. The AI coach will seamlessly adjust to keep you on track. You can also drag and drop the workouts to reschedule them.',
    imageLight: '/home/feature-3.gif',
    imageDark: '/home/feature-3-dark.gif',
  },
  {
    title: 'Activity Details.',
    description:
      'Trainme offers seamless integration with leading activity trackers, including Garmin and Strava, to enhance your fitness experience. Once your training is finished and synced to these third-party platforms, we automatically sync the data to Trainme. You can view the details of your training, including a comprehensive map of your route and detailed lap information. If you have data from multiple sources, we merge the data for you, ensuring a complete and unified view of your activities.',
    imageLight: '/home/feature-6.png',
    imageDark: '/home/feature-6-dark.png',
  },

  {
    title: 'Daily Training Analysis and Score.',
    description:
      'Your coach will analyze your daily training performance, providing you with a comprehensive score and detailed insights. This analysis helps you understand your progress over time, identify strengths and weaknesses, and highlight specific areas for improvement. By receiving tailored feedback, you can make informed decisions about your training regimen and adjust your efforts to maximize results. You can also ask your coach any questions about your training.',
    imageLight: '/home/feature-4.png',
    imageDark: '/home/feature-4.png',
  },

  {
    title: 'Sync your workouts with your calendar and devices.',
    description:
      'Trainme seamlessly integrates with smartwatches, currently supporting most Garmin devices, syncing workouts and providing real-time notifications during your workouts. Stay informed about sessions, progress, and plan updates for a smooth fitness journey.',
    imageLight: '/home/feature-5.png',
    imageDark: '/home/feature-5.png',
  },
];

const HomePublic = () => {
  const { isDarkMode } = useTheme();

  const sliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 300,
    pauseOnHover: false,
    arrows: false,
  };

  const size = 600;

  return (
    <div className='w-full h-full overflow-hidden'>
      <article className='w-full h-full overflow-y-auto scroll text-gray-800 m-0 p-0'>
        <section>
          <Slider {...sliderSettings}>
            {heroImages.map((image, index) => (
              <div
                className='w-full h-72 md:h-96 lg:h-128 xl:h-160 relative my-0'
                key={index}
              >
                <Image
                  src={image.image}
                  alt={image.text}
                  width={1200}
                  height={500}
                  className='w-full h-full absolute top-0 right-0 object-cover opacity-90 bg-gray-200 dark:bg-gray-800'
                  style={{
                    maxWidth: 'auto',
                    height: '100%',
                  }}
                />
                <div className='z-10 h-full container mx-auto'>
                  <h2
                    className={cn([
                      'text-[1.5em] md:text-[2.5em] leading-[1em] md:leading-[1.5em]',
                      'h2 absolute transform -translate-y-1/2 font-bold max-w-[50%] top-1/3 left-16',
                      'text-green-700 text-shadow-2xl shadow-gray-100',
                    ])}
                  >
                    {image.text}
                  </h2>
                  <div
                    className='absolute transform -translate-x-1/2 -translate-y-1/2'
                    style={{ top: '65%', left: '65%' }}
                  >
                    <BannerButton btnColor={image.color} />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </section>
        <section className='container mx-auto max-w-screen-2xl text-gray-900 dark:text-gray-300 py-4 mb-20'>
          {features.map((feature, index) => (
            <div
              key={index}
              className={cn([
                'grid grid-cols-1 md:grid-cols-2 gap-24 items-center text-[1.2em] leading-[1.8em] my-24',
              ])}
            >
              <div
                className={cn([
                  index % 2 === 0 ? 'order-1' : 'order-2',
                  index === 3 ? 'w-1/2 h-auto mx-auto self-start' : 'h-full',
                  'flex items-stretch',
                ])}
              >
                <Image
                  src={isDarkMode ? feature.imageDark : feature.imageLight}
                  alt={feature.title}
                  width={size}
                  height={size}
                  sizes='100vw'
                  style={{
                    width: 'auto',
                    height: '100%',
                  }}
                  unoptimized={feature.imageDark.includes('gif')}
                />
              </div>
              <div
                className={cn([
                  index % 2 === 0 ? 'order-2' : 'order-1',
                  'flex-1',
                ])}
              >
                <h2 className='h2'>{feature.title}</h2>
                <p>{feature.description}</p>
              </div>
            </div>
          ))}
        </section>
        <Footer />
      </article>
    </div>
  );
};

export default HomePublic;
